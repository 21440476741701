import '@factry/bulma'
import '@fortawesome/fontawesome-free/css/all.css'
import { initialize } from '@factry/base'
import App from './App.svelte'

initialize({
  defaultLocale: 'en',
  availableLocales: ['en', 'nl', 'fr'],
  basePath: import.meta.env.VITE_BASE_PATH,
  versions: import.meta.env.VITE_VERSIONS,
})

const app = new App({
  target: document.body,
})

export default app

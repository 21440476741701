<script>
  import Base from '@factry/base'
  import { settings } from '@factry/translation-manager'
  import TranslationsManager from '@factry/translation-manager/src/components/Overview'

  settings.canEditDefaultLocale = true
  settings.canAddTranslations = true
  settings.canRemoveTranslations = true

  const manager = {
    router: {
      '/translations': TranslationsManager,
    },
    menu: {
      translations: {
        url: '/translations',
        label: 'Translations',
        icon: 'fas fa-language',
      },
    },
  }
</script>

<Base options={[manager]} />
